<template>
    <teleport v-if="mountedComponent" to="#header-nav">
        <CBreadcrumbItem>
            <router-link :to="{ name: 'Templates' }"> Templates</router-link>
        </CBreadcrumbItem>
        <CBreadcrumbItem active>Task Template Settings</CBreadcrumbItem>
    </teleport>
    <teleport v-if="mountedComponent" to="#templates-header">
        <span class="template-page__heading">Task Template Settings</span>
    </teleport>

    <div class="template-page">
        <div v-if="mode" class="template-page__content">
            <div v-if="isOnboarding">
                <OnboardingScreen/>
            </div>
            <div v-else @click="formDirty = true">
                <div class="template-page__form">
                    <div class="template-page__form-partial">
                        <TemplateNameInput :model-value="templateData.name" class="template-page__name"
                                           placeholder="Template name" @update:modelValue="v => templateData.name = v"
                                           @on-validation="templateNameError = $event"/>
                    </div>
                </div>
                <div class="template-page__form">
                    <div v-if="isTask" class="template-page__form-partial mt-4">
                        <OptionsDropdown :aria-disabled="!templateData.name || templateNameError"
                                         :options="templateActions" label="Task action"
                                         :model-value="templateData.type" @update:modelValue="onTaskActionChange"/>

                        <DueDateSelector :aria-disabled="!fieldsAvailable" :recurrence="templateData.recurrence"
                                         :permissions="[fieldsAvailable, 'recurrence']"
                                         :due_date_days="templateData.due_date_days" @saved="updateRecurrenceSettings"/>

                        <textarea v-model="templateData.description" :aria-disabled="!fieldsAvailable" maxlength="1000"
                                  class="template-page__description" placeholder="Description">
                        </textarea>
                    </div>
                    <div :aria-disabled="!fieldsAvailable && isTask" class="template-page__form-partial mt-4">
                        <OptionsDropdown :model-value="templateData.standard_template_id"
                                         :options="standardTemplatesOptions" label="Use CC templates"
                                         @update:modelValue="value => selectStandardTemplate(value)"/>

                        <div v-if="templateData.standard_template_id" class="template-page__file">
                            <CIcon name="cilFile"/>
                            <p class="template-page__file-link"
                               @click="toggleDocumentPreviewer(true, 'standard-templates', templateData.standard_template_id)">
                                Preview the document
                            </p>
                        </div>

                        <div v-else>
                            <FileUploadingArea :formats="fileFormats" @fileLoaded="validateAndUploadFile($event)"/>
                            <div v-if="templateData.upload_id" class="template-page__file mt-3">
                                <CIcon name="cilFile"/>
                                <p>{{ selectedFileUpload.name }}</p>
                                <CIcon class="pointer" name="cilTrash" @click="removeUploadedFile"/>
                            </div>
                        </div>

                        <OptionsDropdown v-if="isTask" :model-value="selectedStatus" :options="statuses" label="Status"
                                         @update:modelValue="v => selectedStatus = v"/>
                    </div>
                    <div class="template-page__form-partial" v-if="isContract">
                        <div class="template-page__contract-info">
                            <img src="@/assets/images/requirements.png" alt=""/>
                            <span>File requirements</span>
                            <p>
                                To be able to add the merge fields, only .doc and .docx are supported at the time. Just
                                upload your <strong>contract</strong> Word document to create a template. Do not forget
                                to include the signature(s) merge fields in your template.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="mode && !isOnboarding" class="template-page__footer">
                <CButton color="primary" variant="outline" @click="$router.push('/templates')">Cancel</CButton>
                <CButton :disabled="!fieldsAvailable" color="primary" @click="submit()">Save</CButton>
            </div>
        </div>

        <div v-if="!mode" class="template-page__spinner">
            <CSpinner color="primary"/>
        </div>

        <OnboardingTemplateFormModals type="info-request" :visible="templateModal === 'info-request'"
                                      @confirm="changeOnboardingCreateRequestAction"/>
        <OnboardingTemplateFormModals type="info-acknowledgment" :visible="templateModal === 'info-acknowledgment'"
                                      @confirm="changeOnboardingCreateAcknowledgementAction"/>

        <TemplateFormModals type="error-file" :visible="templateModal === 'error-file'" confirm-button-label="Got it"
                            @confirm="toggleTemplateModel"/>
        <TemplateFormModals type="error-format" :visible="templateModal === 'error-format'"
                            confirm-button-label="Got it" @confirm="toggleTemplateModel"/>
        <TemplateFormModals type="error-size" :visible="templateModal === 'error-size'" confirm-button-label="Got it"
                            @confirm="toggleTemplateModel"/>
        <TemplateFormModals type="error-unexpected" :visible="templateModal === 'error-unexpected'"
                            confirm-button-label="Got it" @confirm="toggleTemplateModel"/>
        <TemplateFormModals type="status-draft" :visible="templateModal === 'status-draft'"
                            confirm-button-label="Save draft" @confirm="submit(true)" @close="toggleTemplateModel"/>

        <UploadModalLoader v-if="selectedFileUpload" :file="selectedFileUpload" :visible="isUploadingFile"/>
        <FormModalLoader :visible="isSubmittingForm"/>
    </div>
    <DocumentPreviewer :visible="documentPreviewer.visible" content-type="origin" :type="documentPreviewer.type"
                       :type-id="documentPreviewer.typeId" :v2="documentPreviewer.v2"
                       @close="toggleDocumentPreviewer(false, null, null)"/>
</template>

<script>
import OnboardingScreen from '@/components/CreateTask/OnboardingScreen.vue';
import TemplateNameInput from '@/components/CreateTask/TemplateNameInput.vue';
import FileUploadingArea from '@/components/CreateTask/FileUploadingArea.vue';
import OptionsDropdown from '@/components/CreateTask/OptionsDropdown.vue';
import DueDateSelector from '@/components/CreateTask/DueDateSelector.vue';
import AddTag from '@/components/CreateTask/AddTag.vue';
import StandardTemplates from '@/api/v2/endpoints/StandardTemplates';
import Templates from "@/api/v2/endpoints/Templates";
import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";
import fileValidator from "@/mixin/fileValidator";
import Uploads from "@/api/v2/endpoints/Uploads";
import OnboardingTemplateFormModals from "@/views/Templates/Template/Components/OnboardingTemplateFormModals.vue";
import TemplateFormModals from "@/views/Templates/Template/Components/TemplateFormModals.vue";
import UploadModalLoader from "@/components/Loaders/UploadModalLoader.vue";
import FormModalLoader from "@/components/Loaders/FormModalLoader.vue";
import fileExtension from "@/mixin/fileExtension";
import {CONTRACT_TEMPLATE_TYPE, TEMPLATE_TYPES} from "@/domain/Entities/Template/templateTypes";
import {TEMPLATE_UPLOAD_OWNER_NAME} from "@/domain/Entities/Upload/uploadTypes";
import {
    CONTRACT_TEMPLATE_FILE_FORMATS,
    TASK_TEMPLATE_FILE_FORMATS
} from "@/domain/Entities/Template/templateFileFormats";
import {
    DRAFT_TEMPLATE_STATUS,
    PUBLISHED_TEMPLATE_STATUS,
    TEMPLATE_STATUSES
} from "@/domain/Entities/Template/templateStatuses";

const MODES = {
    ONBOARDING: 'onboarding',
    CONTRACT: 'contract',
    TASK: 'task',
};

const ACTIONS = {
    ONBOARDING: 'create_template_page',
    CREATE_REQUEST: 'create_request_document',
    CREATE_ACKNOWLEDGEMENT: 'create_request_acknowledgement',
};

export default {
    name: 'TemplateCreate',
    components: {
        FormModalLoader,
        UploadModalLoader,
        TemplateFormModals,
        OnboardingTemplateFormModals,
        AddTag,
        DueDateSelector,
        OptionsDropdown,
        FileUploadingArea,
        TemplateNameInput,
        OnboardingScreen,
        DocumentPreviewer
    },
    mixins: [fileValidator, fileExtension],
    inject: ['toast', 'downloadToast'],
    data() {
        return {
            mode: null,
            formDirty: false,
            mountedComponent: false,
            templateModal: null,
            isSubmittingForm: false,
            isUploadingFile: false,
            selectedFileUpload: null,
            templateNameError: false,
            templateData: {
                name: null,
                type: null,
                due_date_days: 0,
                recurrence: null,
                description: null,
                is_published: false,
                standard_template_id: null,
                upload_id: null,
            },
            actions: [],
            standardTemplates: [],
            statuses: TEMPLATE_STATUSES,
            selectedStatus: DRAFT_TEMPLATE_STATUS,
            documentPreviewer: {
                v2: false,
                type: null,
                typeId: null,
                visible: false,
            }
        };
    },
    computed: {
        isOnboarding() {
            return this.mode === MODES.ONBOARDING;
        },
        isContract() {
            return this.mode === MODES.CONTRACT;
        },
        isTask() {
            return this.mode === MODES.TASK;
        },
        fieldsAvailable() {
            return !!(this.templateData.name && this.templateData.type && !this.templateNameError);
        },
        fileFormats() {
            return this.isContract ? CONTRACT_TEMPLATE_FILE_FORMATS : TASK_TEMPLATE_FILE_FORMATS;
        },
        templateActions() {
            return TEMPLATE_TYPES.filter(type => type.value !== CONTRACT_TEMPLATE_TYPE);
        },
        standardTemplatesOptions() {
            return this.templateData.type
                ? this.standardTemplates
                    .filter(item => item.template_types.includes(this.templateData.type))
                    .map((item) => ({value: item.id, label: item.name}))
                : [];
        },
    },
    watch: {
        selectedStatus(value) {
            this.templateData.is_published = value === PUBLISHED_TEMPLATE_STATUS;
        },
        '$route.query': function () {
            this.setPageMode();
        },
    },
    created() {
        this.loadStandardTemplates();
        this.getActions();
        this.setPageMode();
    },
    mounted() {
        this.mountedComponent = true;
    },
    methods: {
        loadStandardTemplates() {
            StandardTemplates.index().then(({data}) => (this.standardTemplates = data));
        },
        getActions() {
            this.$http.onboarding.getActions().then(response => this.actions = response.data.data);
        },
        getActionStatus(action) {
            const requestedAction = this.actions.find((el) => el.action === action);
            return requestedAction ? requestedAction.checked : false;
        },
        setPageMode() {
            this.mode = this.definePageMode();

            if (this.mode === MODES.CONTRACT) {
                this.templateData.type = CONTRACT_TEMPLATE_TYPE;
            }
        },
        definePageMode() {
            if (this.$route.query.type) {
                const availableTypes = [MODES.CONTRACT, MODES.TASK];

                if (availableTypes.includes(this.$route.query.type)) {
                    return this.$route.query.type;
                }
            }

            if (!this.getActionStatus(ACTIONS.ONBOARDING)) {
                return MODES.ONBOARDING;
            }
        },
        onTaskActionChange(action) {
            this.templateData.type = action;
            this.templateData.standard_template_id = null;

            if (this.formDirty) {
                if (action === 'Request documents' && !this.getActionStatus(ACTIONS.CREATE_REQUEST)) {
                    return this.toggleTemplateModel('info-request');
                }

                if (action === 'Acknowledge' && !this.getActionStatus(ACTIONS.CREATE_ACKNOWLEDGEMENT)) {
                    return this.toggleTemplateModel('info-acknowledgment');
                }
            }
        },
        validateAndUploadFile(event) {
            this.selectedFileUpload = event.target.files[0];

            this
                .validateFormatAndSize(this.selectedFileUpload, this.fileFormats, 1e8)
                .then(() => this.uploadFile())
                .catch(errorType => {
                    this.toggleTemplateModel('error-' + errorType);
                    this.removeUploadedFile();
                });
        },
        uploadFile() {
            this.toggleIsUploadingFile();

            Uploads
                .store({
                    owner_name: TEMPLATE_UPLOAD_OWNER_NAME,
                    owner_type: this.templateData.type,
                    file: this.selectedFileUpload
                })
                .then(response => {
                    this.templateData.upload_id = response.data.id;

                    if (this.isCompatibleWithTheEditor(response.data.name)) {
                        this.defineTemplateNameByUploadedFile();
                        this.createTemplate();
                    }
                })
                .finally(() => this.toggleIsUploadingFile());
        },
        defineTemplateNameByUploadedFile() {
            if (this.templateData.name === null) {
                this.templateData.name = this.removeFileNameExtension(this.selectedFileUpload.name);
            }
        },
        createTemplate() {
            this.toggleIsSubmittingForm();

            Templates.store(this.templateData)
                .then(response => {
                    this.toast('info', 'Template successfully created!');
                    this.$router.push(`/templates/${response.data.id}`);
                })
                .catch(() => this.toggleTemplateModel('error-unexpected'))
                .finally(() => this.toggleIsSubmittingForm());

        },
        submit(force = false) {
            this.toggleTemplateModel();

            if (this.templateData.type === 'Acknowledge' && this.selectedFileUpload === null) {
                return this.toggleTemplateModel('error-file');
            }

            if (!force && this.selectedStatus === 'Draft') {
                return this.toggleTemplateModel('status-draft');
            }

            this.createTemplate();
        },
        updateRecurrenceSettings(data) {
            this.templateData.due_date_days = data.due_date_days;
            delete data.due_date_days;
            this.templateData.recurrence = data;
        },
        changeOnboardingCreateRequestAction(checked = false) {
            this.changeOnboardingAction('create_request_document', checked);
        },
        changeOnboardingCreateAcknowledgementAction(checked = false) {
            this.changeOnboardingAction('create_request_acknowledgement', checked);
        },
        changeOnboardingAction(action, checked = false) {
            if (checked) {
                this.$http.onboarding
                    .changeAction({action})
                    .then(() => {
                        const index = this.actions.findIndex(item => item.action === action);
                        this.actions[index].checked = true;
                    });
            }

            this.toggleTemplateModel();
        },
        toggleDocumentPreviewer(v2, type, typeId) {
            this.documentPreviewer.v2 = v2;
            this.documentPreviewer.type = type;
            this.documentPreviewer.typeId = typeId;
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
        },
        toggleIsUploadingFile() {
            this.isUploadingFile = !this.isUploadingFile;
        },
        toggleIsSubmittingForm() {
            this.isSubmittingForm = !this.isSubmittingForm;
        },
        toggleTemplateModel(type = null) {
            this.templateModal = type;
        },
        selectStandardTemplate(id = null) {
            this.templateData.standard_template_id = id;
            this.removeUploadedFile();
        },
        removeUploadedFile() {
            this.templateData.upload_id = null;
            this.selectedFileUpload = null;
        },
    },
};
</script>

<style lang="scss">
.template-page {
    &__heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #1c262f;
    }

    &__file {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        color: #000000;

        &-link {
            cursor: pointer;
            color: #0068ad;
            text-decoration: underline;
        }

        & svg {
            flex-shrink: 0;
        }

        & p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
        }

        .icon:last-child {
            color: red;
        }
    }

    &__description {
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #9fafbc;
        color: #677a89;
        font-size: 16px;
        height: 112px;

        &:focus {
            outline: none;
        }
    }

    &__footer {
        margin-top: 110px;
        padding-top: 24px;
        border-top: 1px solid #c3cdd5;
        display: flex;
        gap: 15px;
    }

    &__contract-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        & img {
            width: 260px;
        }

        & span {
            font-size: 16px;
            margin-top: 50px;
            margin-bottom: 20px;
            font-weight: 700;
            color: #1c262f;
        }

        & p {
            font-size: 14px;
            max-width: 400px;
            color: #677a89;
            text-align: center;
            font-weight: 400;
        }
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        &_request {
            & .template-page__name {
                max-width: 521px;
            }
        }

        &-partial {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 24px;

            max-width: 521px;

            &_spaced {
                padding-top: 95px;
            }
        }
    }

    &__content {
        background: white;
        border-radius: 8px;
        padding: 40px 24px;
    }

    &__spinner {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        min-height: 70vh;

        background: #ffffff57;
        border-radius: 8px;
    }

    &__modal {
        &-basic {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 60px;
            width: 688px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            text-align: center;
            margin-bottom: 24px;
        }

        &-title {
            font-size: 24px;
            font-weight: 600;
            color: #1c262f;
        }

        &-text {
            font-size: 18px;
            color: #677a89;
        }

        &-icon {
            margin-bottom: 25px;

            height: 75px !important;
            width: 75px !important;
            color: #bac6cf;

            &_info {
                height: 48px !important;
                width: 48px !important;
                color: #0068ad;
            }

            &_warning {
                height: 80px !important;
                width: 80px !important;
                color: #bac6cf;
            }
        }

        &-cancellable {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 50px;
        }

        &-actions {
            display: flex;
            gap: 16px;
        }

        &-buttons {
            display: flex;
            gap: 16px;
            margin-top: 32px;

            & button {
                min-width: 200px;
            }
        }
    }
}
</style>
